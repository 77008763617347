//todo: PROMO URL

const router = [
  {
    name: 'app.root',
    abstract: true,
    template: '<linda-app />'
  },
  {
    name: 'app.root.l1',
    abstract: true,
    template: '<linda-view-layer-one />'
  },
  {
    name: 'app.root.l1.l2',
    abstract: true,
    template: '<linda-view-layer-two />'
  },
  {
    name: 'app.root.l1.l2.home',
    url: '/',
    template: '<linda-homepage />'
  },
  {
    name: 'app.root.l1.l2.vip',
    url: '/vip',
    template: '<linda-vip />'
  },
  {
    name: 'app.root.l1.l2.404',
    url: '/404',
    template: '<not-found />'
  },
  {
    name: 'app.root.paynplay',
    url: '/fast-deposit',
    template: '<linda-zimpler-paynplay-page />'
  },
  //--PAYMENT CALLBACKS--
  {
    name: 'app.root.paymentcb',
    url: '/payment/:act?order_id&identifier',
    template: '<linda-payment-callback />'
  },
  {
    name: 'app.root.withdraw-paymentcb-success',
    url: '/withdraw-payment/:act',
    template: '<linda-payment-callback />'
  },
  //--GAME--
  {
    name: 'app.root.game',
    url: '/pre-game/:name',
    template: '<linda-game-page />'
  },
  // <MACHINES> ...
  {
    name: 'app.root.claw_games',
    abstract: true,
    private: true,
    template:'<ui-view></ui-view>'
  },
  {
    name: 'app.root.claw_games.gamepage',
    url: '/machine/:name',
    template: '<batman-view-layer1 />'
  },
  //- </MACHINES>
  {
    name: 'app.root.real',
    url: '/play/:name',
    template: '<linda-game-field get-state-params-to-scope="state" game="{alias: state.name}" mode=" \'real\' " />',
    gamePage: false,
    private: true,
    params: {
      mode: 'real'
    }
  },
  {
    name: 'app.root.demo',
    url: '/game/:name',
    template: '<linda-game-field get-state-params-to-scope="state" game="{alias: state.name}" mode=" \'demo\' " />',
    gamePage: false,
    params: {
      mode: 'demo'
    }
  },
  //--PROFILE--
  {
    name: 'app.root.l1.profile',
    abstract: true,
    template: '<linda-profile-page />',
    private: true
  },
  {
    name: 'app.root.l1.profile.dashboard',
    url: '/account',
    template: '<linda-profile-staff />',
  },
  {
    name: 'app.root.l1.l2.shop',
    url: '/shop',
    template: '<linda-shop-page scroll-to-top></linda-shop-page>',
  },
  {
    name: 'app.root.l1.profile.bonuses',
    url: '/account/bonuses',
    template: '<linda-profile-bonus-list />',
  },
  {
    name: 'app.root.l1.profile.info',
    url: '/account/info',
    template: '<linda-profile-settings />',
  },
  {
    name: 'app.root.l1.profile.history',
    url: '/account/balance-history',
    template: '<linda-cashbox-balance-information />',
  },
  {
    name: 'app.root.l1.profile.verification',
    url: '/account/verification',
    template: '<dvs-page scroll-to-top></dvs-page>',
    private: true,
    params: {
      page: 'verification'
    }
  },
  //--CASINO--
  {
    name: 'app.root.l1.casino',
    abstract: true,
    template: '<linda-casino-section />'
  },
  {
    name: 'app.root.l1.l2.casino',
    url: '/casino',
    template: '<linda-view-layer-five />',
  },
  {
    name: 'app.root.l1.casino.gamehall',
    url: '/games',
    template: '<batman-game-hall1 />',
  },
  {
    name: 'app.root.l1.casino.live-casino',
    url: '/live-casino',
    template: '<batman-game-hall2 />',
    params: {
        name: 'live-casino'
    }
  },
  {
    name: 'app.root.l1.casino.my-games',
    url: '/games/favourites',
    template: '<batman-game-box-favourites1 />',
    private: true,
    params: {
        name: 'my-games'
    }
  },
  {
    name: 'app.root.l1.casino.category',
    url: '/games/:name',
    template: '<batman-game-hall2 />'
  },
  {
    name: 'app.root.l1.casino.provider',
    url: '/casino-providers/:name',
    template: '<batman-game-hall3 />'
  },
  {
    name: 'app.root.l1.casino.continue-playing',
    url: '/last-played',
    template: '<batman-recently-played-list1 />',
    params: {
        name: 'recently-played'
    }
  },
  //--SPORT--
  {
    name: 'app.root.l1.l2.sport',
    abstract: true,
    template: '<linda-sport-page to-top-on-state-change></linda-sport-page>'
  },
  {
    name: 'app.root.l1.l2.sport.prelive',
    url: '/sport?sportids&catids&champids&tabs&eventid&betslipoddids',
    template: '',
    params: {
      page: 'prelive'
    }
  },
  {
    name: 'app.root.l1.l2.sport.live',
    url: '/sport/live?sportids&catids&champids&tabs&eventid&betslipoddids',
    template: '',
    params: {
      page: 'live'
    }
  },
  {
    name: 'app.root.l1.l2.sport.virtual',
    url: '/sport/virtual?sportids&catids&champids&tabs&eventid&betslipoddids',
    template: '',
    params: {
      page: 'vflm'
    }
  },
  {
    name: 'app.root.l1.l2.sport.mybets',
    url: '/sport/mybets',
    template: '',
    params: {
      page: 'mybets'
    }
  },
  //--PROMOTIONS--
  {
    name: 'app.root.l1.l2.promotions',
    abstract: true,
    template: '<linda-view-layer-four />',
  },
  {
    name: 'app.root.l1.l2.promotions.all',
    url: '/promotions',
    template: '<linda-view-layer-six />',
  },
  {
    name: 'app.root.l1.l2.promotions.casino',
    url: '/casino-promotions',
    template: '<batman-promo-list1 class="layout-promotions" />',
    params: {
      type: 'casino',
    }
  },
  {
    name: 'app.root.l1.l2.promotions.sport',
    url: '/sport-promotions',
    template: '<batman-promo-list1 class="layout-promotions" />',
    params: {
      type: 'sport',
    }
  },
  {
    name: 'app.root.l1.l2.promotions.live-casino',
    url: '/promotions-live',
    template: '<batman-promo-list1 class="layout-promotions" />',
    params: {
      type: 'live-casino',
    }
  },
  {
    name: 'app.root.l1.l2.promotion',
    url: '/promotions/:name',
    template: '<linda-promo-page />',
  },
  //--TOURNAMENTS--
  {
    name: 'app.root.l1.l2.tournaments',
    url: '/tournaments',
    template: '<batman-tournament-list1 players="true" competitors="3"/>'
  },
  {
    name: 'app.root.l1.l2.tournament',
    url: '/tournaments/:name',
    template: '<linda-tournament-page />'
  },
  {
    name: 'app.root.l1.l2.euro2024',
    url: '/tournaments/euro2024',
    template: '<batman-view-layer3 scroll-to-top></batman-view-layer3>'
  },
  {
    name: 'app.root.l1.l2.sporttournament',
    url: '/sport-tournament',
    template: '<batman-view-layer2 />'
  },
  //--INFO--
  {
    name: 'app.root.l1.l2.l3',
    abstract: true,
    template: '<linda-view-layer-three />',
  },
  {
    name: 'app.root.l1.l2.l3.contact-us',
    url: '/contact-us',
    template: '<linda-info-page />',
    params: {
        name: 'contact-us'
    }
  },
  {
    name: 'app.root.l1.l2.l3.rules',
    url: '/rules',
    template: '<linda-info-page />',
    params: {
        name: 'rules'
    }
  },
  {
    name: 'app.root.l1.l2.l3.responsible-gaming',
    url: '/responsible-gaming',
    template: '<linda-info-page />',
    params: {
        name: 'responsible-gaming'
    }
  },
  {
    name: 'app.root.l1.l2.l3.privacy-policy',
    url: '/privacy-notice',
    template: '<linda-info-page />',
    params: {
        name: 'privacy-notice'
    }
  },
  {
    name: 'app.root.l1.l2.l3.cookies-policy',
    url: '/cookies-notice',
    template: '<linda-info-page />',
    params: {
      name: 'cookies-notice'
    }
  },
  {
    name: 'app.root.l1.l2.l3.about',
    url: '/about-us',
    template: '<linda-info-page />',
    params: {
        name: 'about'
    }
  },
  {
    name: 'app.root.l1.l2.l3.sitemap',
    url: '/sitemap',
    template: '<linda-sitemap />'
  },
  {
    name: 'app.root.l1.l2.l3.faq',
    url: '/faq',
    template: '<linda-info-page />',
    params: {
        name: 'faq'
    }
  },
  {
    name: 'app.root.missedData',
    url: '/missed-data',
    template: '<linda-missed-data-form><linda-missed-data-form/>'
  },
  {
    name: 'app.root.l1.l2.halloween',
    url: '/seasons/halloween',
    template: '<halloween-promo-mobile class="halloween-unset halloween-fezbet-m" scroll-to-top="" ></halloween-promo-mobile>'
  },
  {
    name: 'app.root.seasons',
    url: '/seasons/summer',
    private: true,
    template: '<summer-promo-wrapper scroll-to-top="" class="seasons-promo-page"></summer-promo-wrapper>'
  },
  {
    name: 'app.root.summer-game',
    url: '/seasons-summer-game',
    private: true,
    template: `<summer-promo-page scroll-to-top=""></summer-promo-page>`,
  },
  {
    name: 'app.root.l1.l2.lamborghini',
    url: '/lamborghini-promo',
    template: `<linda-view-layer-seven></linda-view-layer-seven>`,
    params: {
      name: 'lamborghini',
    },
  },
  {
    name: 'app.root.l1.l2.ferrari',
    url: '/ferrari-promo',
    template: '<batman-car-promo class="ferrari-promotion"></batman-car-promo>',
  },
];

export { router };
