const _temp0 = require("./directive/bonus-activation.js");
const _temp1 = require("./directive/days-or-hours-from-now.js");
const _temp2 = require("./directive/from-utc-to-local-date.js");
const _temp3 = require("./directive/hide-element-if-season-promo-exist.js");
const _temp4 = require("./directive/zet-iframe-quiz-url.js");
const _temp5 = require("./directive/animation/random.js");
const _temp6 = require("./directive/animation/to-top-on-state-change.js");
const _temp7 = require("./directive/animation/vip-level-swipe.js");

module.exports = {
  "bonus-activation.js": _temp0,
  "days-or-hours-from-now.js": _temp1,
  "from-utc-to-local-date.js": _temp2,
  "hide-element-if-season-promo-exist.js": _temp3,
  "zet-iframe-quiz-url.js": _temp4,
  "animation":   {
    "random.js": _temp5,
    "to-top-on-state-change.js": _temp6,
    "vip-level-swipe.js": _temp7
  }
}