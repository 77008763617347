import '@b2c/core/app/service/domain/user';
import '@b2c/core/app/service/rx/system$';
import { filter } from 'rxjs/operators';
import { gsap } from 'gsap';
import InertiaPlugin from '@b2c/core/app/libs/gsap/InertiaPlugin.min.js';
import Draggable from '@b2c/core/app/libs/gsap/Draggable.min.js';

(function () {

    'use strict';

    const directive = { name: 'vipLevelSwipe' };

    controller.$inject = [ '$timeout', 'user', 'system$' ];

    function controller( $timeout, _user, _system$ ){

        function link( scope, element, attrs ){

            let index = parseInt( attrs[ directive.name ] );

            gsap.registerPlugin(InertiaPlugin);
            gsap.registerPlugin(Draggable);

            let maxEndValueX = index === 5 ? 0 : -220;

            Draggable.create( element[0], {
                type: 'x,y',
                edgeResistance: 0.65,
                bounds: {top: 0, left: 0, width: 500, height: 495, minY: 0, maxY: 0},
                inertia: true,
                snap: {
                    x: function(endValue) {
                        return endValue > -100 ? 0 : maxEndValueX;
                    },
                    y: function(endValue) {
                        return 0;
                    }
                }
            });

            goToActive();

            let subscription = _system$.pipe(filter( m => m.action === 'login' )).subscribe( () => {
                goToActive();
            } );

            scope.$on( '$destroy', () => {
                subscription.unsubscribe();
            } );

            function goToActive(){
                if( index < _user.profile.level && _user.profile.level > 1 ){
                    $timeout( () => {
                        gsap.to( element[0], { css: {zIndex:100+index}, duration: 0 });
                        gsap.to( element[0], { x: -220, duration: .5, ease: 'power3.out' });
                    }, index*300 + 1000 )
                }
            }

        }


        return {
            restrict: 'A',
            link
        };
    }

    app.directive( directive.name, controller );

})();
